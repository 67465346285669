<template>
  <div>
		<!-- Notes -->
    <hr/>
    <h4 class="subtitle-form">{{$t('components.EditNotes.title')}}</h4>
		<div v-for="(noteToPatch, index) in notesToPatch" :key="index" class="text-infos bg-white mb-2">
			<b-row>
				<!-- Sélecteur de la catégorie de note -->
				<b-col cols="10">
					<Dropdown
						ref="dropdown"
						label="designation"
						:options="noteTopics"
						@input="noteTopic => selectTopic(noteTopic, noteToPatch)"
						:value="noteToPatch.noteTopic ? noteToPatch : null"
						:placeholder="$t('components.EditNotes.noteTopicPlaceholder')"
					/>
				</b-col>
				<!-- Bouton de suppression -->
				<b-col cols="2" class="pl-0">
					<button class="btn align-bottom" @click="onDelete(noteToPatch)">
						<img :src="delIcon" alt="icon delete action" />
					</button>
				</b-col>
			</b-row>
			<b-row class="mt-2">
				<!-- Zone de saisie du texte de la note -->
				<b-col cols="12">
					<b-textarea
						rows="3"
						v-model="noteToPatch.text"
						class="text-infos whitout-bg py-1 px-2"
						:placeholder="$t('components.EditNotes.notePlaceholder')"
					/>
					<p class="text-activity-notes position-rigth font-italic disable-margin-bottom" v-if="noteToPatch.createdAt">
            {{noteToPatch.author ? (noteToPatch.author + ",") : ""}} {{ formatDate(noteToPatch.createdAt, "date") }} à {{ formatDate(noteToPatch.createdAt, "time") }}
          </p>
				</b-col>
			</b-row>
		</div>
		<div v-if="notesToPatch.length === 0 || (notesToPatch[notesToPatch.length - 1].text != null && notesToPatch[notesToPatch.length - 1].text !== '')">
			<!-- Bouton d'ajout d'une note -->
			<b-row class="text-activity-notes">
				<b-col cols="2">
					<button
						type="button" 
						class="btn btn-light pt-0 mt-0" 
						@click="addNote"
					>
						<img :src="plusIcon" alt="icon add action"/>
					</button>
				</b-col>
				<b-col cols="10" class="p-0">
					{{ $t('components.EditNotes.addAnotherNote') }}
				</b-col>
			</b-row>
		</div>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import delIcon from "@/assets/images/delete.svg";
import plusIcon from "@/assets/images/plus_add_supply.svg";
import DetailUpdateMixin from "@/mixins/DetailUpdateMixin";
import LoadFormPartMixin from "@/mixins/LoadFormPartMixin";
import Dropdown from '@/components/activity/edit/Dropdown.vue';
import {formatDate} from "@/utils/commons";

export default {
	mixins: [DetailUpdateMixin, LoadFormPartMixin],
  props: {
    useForm: {
      type: String,
      require: true,
    },
  },
	data() {
    return {
      page: this.useForm,
      delIcon: delIcon,
			plusIcon: plusIcon,
      loadComponent: false,
			notesToPatch: [],
			previousNotes: []
    };
	},
	components: {
    Dropdown
  },
	computed: {
    ...mapGetters({
      noteTopics: "noteTopics",
      notes: "notes",
    }),
	},
	created() {
		this.init();
	},
	methods: {
		async init() {
			this.loadComponent = false;
			// Récupération de la liste de toutes les catégories de notes
			await this.$store.dispatch("getNoteTopics");
			if (this.$route.name === "activity.edit" && this.currentEntry.notes) {
				// Si l'activité avait déjà des notes, on les récupère
				await this.$store.dispatch("getAllNotes", { activity: this.currentEntry.id, detail: true });
				// Le tableau previousNotes n'évoluera pas et permettra d'identifier les changements
				this.previousNotes = JSON.parse(JSON.stringify(this.notes)); 
				this.notesToPatch = JSON.parse(JSON.stringify(this.notes));
				this.notesToPatch.forEach(note => {
					note.designation = note.topic_designation;
					delete note.topic_designation;
				});
			}
			else {
				this.addNote();
			}
			this.loadComponent = true;
		},

		/**
		 * Sélection d'un thème
		 */
		selectTopic(noteTopic, noteToPatch) {
			noteToPatch.noteTopic = noteTopic ? noteTopic.id : null
			noteToPatch.designation = noteTopic ? noteTopic.designation : null
		},

		/**
		 * Suppression d'une note
		 */
		onDelete(note) {
			if(note.id !== undefined) {
				// Si la note existait déjà sur l'activité, on supprime à partir de son id
				this.notesToPatch = this.notesToPatch.filter((elem) => elem.id === undefined || elem.id !== note.id)
			} else {
				// Sinon, on l'identifie par son texte et son thème
				this.notesToPatch = this.notesToPatch.filter((elem) => elem.text !== note.text || elem.noteTopic !== note.noteTopic)
			}
		},
		
		/**
		 * Ajoute un champ de saisie vide pour une nouvelle note
		 */
		addNote() {
			this.notesToPatch.push({
				noteTopic: null,
				text: null,
				designation: null 
			});
		},
		formatDate,
	
		/**
     * Description: Confirme le chargement des données de ce composant.
     * @return {Boolean} Component loaded.
     */
    validateForm: function () {
      this.isValid = true;
      return this.loadComponent;
    },
	},
	watch: {
		notesToPatch: {
			handler: async function() {
				await this.$store.dispatch(this.storeEditAction, { notesToPatch: this.notesToPatch });
			}
		},
		previousNotes: {
			handler: async function() {
				await this.$store.dispatch(this.storeEditAction, { previousNotes: this.previousNotes });
			}
		}
	}

};
</script>
<style lang="scss" scoped>
	button {
		font-size: 0.8em;
		font-weight: bold;

		img {
			width: 1.5em;
		}
	}

	/**
	 * Text align right.
	 */
	.position-rigth {
		text-align: right;
	}

	/**
	 * Disable margin bottom.
	 */
	.disable-margin-bottom {
		margin-bottom: 0px;
	}
</style>